@import "../theme.scss";

.tree {
  background-color: transparent;
  :global {
    .ant-tree-iconEle {
      color: $text-color-1;
      font-size: 18px;
      margin-right: 4px;
    }

    .ant-tree-title a {
      font-size: 18px;
      font-weight: 400;
      color: $text-color-2 !important;
    }
    .ant-tree-node-content-wrapper {
      flex: 1;
      min-width: 0;
    }
    .ant-tree-treenode {
      padding-bottom: 16px;
      width: 100%;
    }
  }
  &.dark {
    :global {
      .ant-tree-title a {
        color: $dark-text-color-2 !important;
      }
    }
  }
}
