@import "../theme.scss";

.modal {
  :global {
    .ant-modal-content {
      border-radius: 8px;
      padding: 0px;
    }

    .ant-modal-close {
      display: none;
    }
  }
}

.card {
  padding: 16px;
  border-radius: 8px;
}
