@import "../theme.scss";

.box {
  display: flex;
  align-items: center;
  height: fit-content;
  transition: $transition-1;
  &.pointer {
    cursor: pointer;
  }
}

.size1 {
  width: 12px;
  height: 12px;
  svg,
  img {
    width: 12px;
    height: 12px;
  }
}

.size2 {
  width: 16px;
  height: 16px;
  svg,
  img {
    width: 16px;
    height: 16px;
  }
}

.size3 {
  width: 20px;
  height: 20px;
  svg,
  img {
    width: 20px;
    height: 20px;
  }
}

.size4 {
  width: 24px;
  height: 24px;
  svg,
  img {
    width: 24px;
    height: 24px;
  }
}

.size5 {
  width: 32px;
  height: 32px;
  svg,
  img {
    width: 32px;
    height: 32px;
  }
}

.size6 {
  width: 40px;
  height: 40px;
  svg,
  img {
    width: 40px;
    height: 40px;
  }
}
