@import "../theme.scss";

.collapse {
  padding: 16px;
  background-color: $background-color-1;
  :global {
    .ant-collapse-header {
      padding: 0px 0px 0px 0px !important;
      flex-direction: row-reverse;
      color: $text-color-2;
      border-radius: 0px !important;
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        padding: 0px 0px 16px 0px !important;
        border-bottom: 1px solid $border-color-2;
      }
    }

    .ant-collapse-content-box {
      padding: 16px 0px 0px 0px !important;
    }
  }
  &.dark {
    background-color: $dark-background-color-2;
    :global {
      .ant-collapse-header {
        color: $dark-text-color-2;
        border-color: $dark-border-color-1;
      }
    }
  }
}
