@import "../theme.scss";

.themeBody {
  height: 100%;
  background-color: #f3f3f3;

  &.dark {
    background-color: #060c13;
  }
}
