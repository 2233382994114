@import "../theme.scss";

.icon {
  cursor: pointer;
  color: $text-color-1;
  &.dark {
    color: $dark-text-color-1;
  }
}

.input {
  font-size: 16px;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.row {
  padding: 0px 12px;
}

.card {
  width: 100%;
}
