.drawerTree {
  overflow: auto;
  /* IE and Edge */
  scrollbar-width: none;
}

.drawerTree::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.drawerButton {
  color: rgba(6, 12, 19, 0.65);
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0px 8px;
  font-size: 18px;
  line-height: 140%;
  &.dark {
    color: rgba(255, 255, 255, 0.65);
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #3972ac;
    }
  }
  span {
    margin: 0 !important;
  }
}

.icon {
  width: calc(16px * 1.4);
  height: calc(16px * 1.4);
}

.treeTitle {
  :global {
    .anticon {
      width: 20px;
      height: 20px;
    }

    img {
      object-fit: contain;
      width: 20px;
      height: 20px;
    }
  }
}

.title {
  font-size: 16px;
  line-height: 140%;
}
