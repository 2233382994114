@import "../theme.scss";

.card {
  border-radius: 8px;
  padding: 16px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  // height: 156px;
  height: 168px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  margin: 0px auto;
  svg {
    width: 32px;
    height: 32px;
    color: $primary-color;
    path {
      fill: $primary-color;
    }
  }
  img {
    width: 32px;
    height: 32px;
  }
}
