@import "../theme.scss";

.dark {
  color: $dark-text-color-1;
}
.box {
  :global {
    tbody > tr {
      &:first-child {
        display: none;
      }
    }
  }
}
