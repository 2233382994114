@import "../theme.scss";

.anchor {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  user-select: none;
  &.left {
    left: 0px;
    right: unset;
  }
}
