@import "../theme.scss";

.box {
  padding: 0px 8px;
}
.title {
  color: var(--subheader-text-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
