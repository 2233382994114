@import "../theme.scss";

.box {
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  box-shadow: 0px -11px 8px -10px rgba(#000000, 0.1);
}

.tab {
  padding: 20px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: $transition-1;
  // border-bottom: 2px solid transparent;
  border-bottom: none;
  color: $text-color-5;

  &.dark {
    color: $dark-text-color-2;
  }

  &.active {
    color: $primary-color;
    border-color: $border-color-1;
  }
}
