@import "../theme.scss";

.box {
  position: absolute;
  right: 64px;
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: transparent !important;
}

.themeButton {
  @extend .button;
  color: $text-color-1;
  transition: all 0.2s;

  .dark & {
    color: $dark-text-color-2;
  }
  &.invisible {
    display: none;
  }
}

.refreshButton {
  @extend .button;
  background-color: $primary-color;
  color: $text-color-4;

  &:hover {
    color: $text-color-4 !important;
  }
  .dark & {
    color: $dark-text-color-4;

    &:hover {
      color: $dark-text-color-4 !important;
    }
  }
}
