@import "../theme.scss";

.alert {
  padding: 18px;
  z-index: 900;
  position: absolute;
  bottom: 62px;
  left: 0;
  right: 0;
  * {
    color: $text-color-6;
  }
  background: $background-color-3;
  display: none;
  &.dark {
    * {
      color: $dark-text-color-5;
    }
    background: $dark-background-color-3;
  }
  &.visible {
    display: flex;
  }
}
