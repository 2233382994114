@import "../theme.scss";

.errorScreen {
  text-align: center;
}

.dark {
  color: $dark-text-color-1;
}

.button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.themeButton {
  @extend .button;
  color: $text-color-1;

  .dark & {
    color: $dark-text-color-1;
  }
}

.refreshButton {
  @extend .button;
  background-color: $primary-color;
  color: $text-color-4;

  &:hover {
    color: $text-color-4 !important;
  }
  .dark & {
    color: $dark-text-color-4;

    &:hover {
      color: $dark-text-color-4 !important;
    }
  }
}
