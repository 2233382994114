@font-face {
  font-family: Open Sans;
  src: url(./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: Open Sans;
  src: url(./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf);
  font-weight: bold;
}

* {
  font-family: Open Sans !important;
}

a {
  color: #3972ac;
  text-decoration: none;
}

.lawLinkDisabled {
  color: currentColor !important;
  pointer-events: none;
  color: inherit;
  text-decoration: none;
  cursor: default;
  background-color: transparent !important;
}

.markdown {
  color: #060c13;
}

.markdown.dark {
  color: white;
}

.appBody {
  height: 100%;
  padding: 0px 16px;
  padding-bottom: 16px;
  overflow: auto;
  /* IE and Edge */
  scrollbar-width: none;
}

.appBody::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

:root:has(.app.dark) {
  --subheader-text-color: rgba(255, 255, 255, 0.25);
  --background-color-3: rgba(255, 255, 255, 0.12);
  --border-color-3: #253e58;
  --tag-text-color: #336495;
}
:root:has(.app:not(.dark)) {
  --subheader-text-color: rgba(6, 12, 19, 0.25);
  --background-color-3: rgba(6, 12, 19, 0.06);
  --border-color-3: #a9c1d1;
  --tag-text-color: #3972ac;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

* {
  touch-action: manipulation;
}

.body {
  position: relative;
}

html,
body {
  overscroll-behavior: none;
}

#root {
  width: 100%;
  height: 100%;
}

.ant-card {
  border: none !important;
}

.ant-card-body {
  padding: 0px !important;
}

.ant-drawer-content-wrapper {
  width: 100% !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-tree-treenode {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.searchedText {
  background-color: yellow;
  color: #060c13;
}

.searchedTextCurrent {
  background-color: rgb(255, 149, 0);
  color: #060c13;
}

.backButton svg {
  color: var(--subheader-text-color);
}
.backButton:hover {
  background-color: transparent !important;
}

hr {
  color: #000;
}

span.rvts0 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts1,
span.rvts1 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts2,
span.rvts2 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts3 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts4,
span.rvts4 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts5 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts6,
span.rvts6 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts7 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts8,
span.rvts8 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts9 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts10,
span.rvts10 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts11 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts12,
span.rvts12 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts13 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts14,
span.rvts14 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts15 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts16,
span.rvts16 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts17 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts18,
span.rvts18 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts19 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts20,
span.rvts20 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts21 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts22,
span.rvts22 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts23 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts24,
span.rvts24 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts25 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts26,
span.rvts26 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts27 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts28,
span.rvts28 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts29 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts30,
span.rvts30 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts31 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts32,
span.rvts32 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts33 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: normal;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts34,
span.rvts34 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: normal;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts35 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts36,
span.rvts36 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: italic;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts37 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  vertical-align: super;
  text-decoration: none;
}

a.rvts38,
span.rvts38 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  vertical-align: super;
  text-decoration: none;
}

span.rvts39 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

span.rvts40 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  vertical-align: sub;
  text-decoration: none;
}

a.rvts41,
span.rvts41 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  vertical-align: sub;
  text-decoration: none;
}

span.rvts42 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts43,
span.rvts43 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts44 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts45,
span.rvts45 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts46 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts47,
span.rvts47 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts48 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts49,
span.rvts49 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts50 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts51,
span.rvts51 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts52 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts53,
span.rvts53 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts54 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts55,
span.rvts55 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts56 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts57,
span.rvts57 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

span.rvts58 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts59,
span.rvts59 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts60 {
  font-size: 8pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts61,
span.rvts61 {
  font-size: 8pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts62 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
}

a.rvts63,
span.rvts63 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 4px;
  text-decoration: none;
}

span.rvts64 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts65,
span.rvts65 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts66 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts67,
span.rvts67 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts68 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
}

a.rvts69,
span.rvts69 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 4px;
  text-decoration: none;
}

span.rvts70 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
}

a.rvts71,
span.rvts71 {
  font-size: 18pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  letter-spacing: 4px;
  text-decoration: none;
}

span.rvts72 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts73,
span.rvts73 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts74 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts75,
span.rvts75 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts76 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
}

a.rvts77,
span.rvts77 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 4px;
  text-decoration: none;
}

span.rvts78 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
}

a.rvts79,
span.rvts79 {
  font-size: 20pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  letter-spacing: 4px;
  text-decoration: none;
}

span.rvts80 {
  font-size: 12pt;
  font-family: "Arial Unicode MS", "Lucida Sans Unicode", "Arial";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts81,
span.rvts81 {
  font-size: 12pt;
  font-family: "Arial Unicode MS", "Lucida Sans Unicode", "Arial";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts82 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}

a.rvts83,
span.rvts83 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

span.rvts84 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts85,
span.rvts85 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts86 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts87,
span.rvts87 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts88 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts89,
span.rvts89 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

span.rvts90 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}

a.rvts91,
span.rvts91 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

span.rvts92 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  text-decoration: none;
}

a.rvts93,
span.rvts93 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

span.rvts94 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  text-decoration: none;
}

a.rvts95,
span.rvts95 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

a.rvts96,
span.rvts96 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts96:hover {
}

a.rvts97,
span.rvts97 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts97:hover {
}

a.rvts98,
span.rvts98 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

a.rvts98:hover {
}

a.rvts99,
span.rvts99 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts99:hover {
  color: #090;
}

a.rvts100,
span.rvts100 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

a.rvts100:hover {
}

a.rvts101,
span.rvts101 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

a.rvts101:hover {
}

a.rvts102,
span.rvts102 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  color: #c00909;
  text-decoration: none;
}

a.rvts102:hover {
  color: #dd330f;
}

a.rvts103,
span.rvts103 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #c00909;
  text-decoration: none;
}

a.rvts103:hover {
  color: #dd330f;
}

a.rvts104,
span.rvts104 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts104:hover {
  color: #090;
}

a.rvts105,
span.rvts105 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

a.rvts105:hover {
}

a.rvts106,
span.rvts106 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts106:hover {
}

a.rvts107,
span.rvts107 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  vertical-align: super;
  text-decoration: none;
}

a.rvts107:hover {
  color: #090;
}

a.rvts108,
span.rvts108 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

a.rvts108:hover {
}

a.rvts109,
span.rvts109 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

a.rvts109:hover {
}

a.rvts110,
span.rvts110 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts110:hover {
}

a.rvts111,
span.rvts111 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  color: #c00909;
  text-decoration: none;
}

a.rvts111:hover {
  color: #dd330f;
}

a.rvts112,
span.rvts112 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts112:hover {
  color: #090;
}

a.rvts113,
span.rvts113 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #c00909;
  text-decoration: none;
}

a.rvts113:hover {
  color: #dd330f;
}

a.rvts114,
span.rvts114 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #c00909;
  text-decoration: none;
}

a.rvts114:hover {
  color: #dd330f;
}

a.rvts115,
span.rvts115 {
  font-size: 16pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts115:hover {
  color: #090;
}

a.rvts116,
span.rvts116 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts116:hover {
  color: #090;
}

a.rvts117,
span.rvts117 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  vertical-align: super;
  text-decoration: none;
}

a.rvts117:hover {
}

a.rvts118,
span.rvts118 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  color: #c00909;
  text-decoration: none;
}

a.rvts118:hover {
  color: #dd330f;
}

a.rvts119,
span.rvts119 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

a.rvts119:hover {
}

a.rvts120,
span.rvts120 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts120:hover {
  color: #090;
}

a.rvts121,
span.rvts121 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts121:hover {
  color: #090;
}

a.rvts122,
span.rvts122 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts122:hover {
}

a.rvts123,
span.rvts123 {
  font-size: 12pt;
  font-family: "Courier New";
  font-style: normal;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts123:hover {
  color: #090;
}

a.rvts124,
span.rvts124 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  letter-spacing: 2px;
  text-decoration: none;
}

a.rvts124:hover {
}

a.rvts125,
span.rvts125 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts125:hover {
  color: #090;
}

a.rvts126,
span.rvts126 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
  color: #060;
  vertical-align: sub;
  text-decoration: none;
}

a.rvts126:hover {
  color: #090;
}

a.rvts127,
span.rvts127 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  text-decoration: none;
}

a.rvts127:hover {
}

a.rvts128,
span.rvts128 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

a.rvts128:hover {
}

a.rvts129,
span.rvts129 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;

  text-decoration: none;
}

a.rvts129:hover {
}

a.rvts130,
span.rvts130 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;

  text-decoration: none;
}

a.rvts130:hover {
}

a.rvts131,
span.rvts131 {
  font-size: 8pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;

  vertical-align: sub;
  text-decoration: none;
}

a.rvts131:hover {
}

a.rvts132,
span.rvts132 {
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts132:hover {
  color: #090;
}

a.rvts133,
span.rvts133 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;

  text-decoration: none;
}

a.rvts133:hover {
}

a.rvts134,
span.rvts134 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: normal;
  color: #060;
  text-decoration: none;
}

a.rvts134:hover {
  color: #090;
}

a.rvts135,
span.rvts135 {
  font-size: 12pt;
  font-family: "Arial Unicode MS", "Lucida Sans Unicode", "Arial";
  font-style: normal;
  font-weight: bold;
  color: #060;
  text-decoration: none;
}

a.rvts135:hover {
  color: #090;
}

a.rvts136,
span.rvts136 {
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  color: #c00909;
  text-decoration: none;
}

a.rvts136:hover {
  color: #dd330f;
}

a.rvts137,
span.rvts137 {
  font-size: 14pt;
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  color: #c00909;
  text-decoration: none;
}

a.rvts137:hover {
  color: #dd330f;
}

.rvps0 {
  text-align: justify;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 0 10px 30px;
}

.rvps1 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 0 0 0;
}

.rvps2 {
  text-align: justify;
  text-indent: 30px;
  padding: 0 0 0 0;
  margin: 0 0 10px 0;
}

.rvps3 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 30px 10px 30px;
}

.rvps4 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 20px 0 10px 0;
}

.rvps5 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 30px 0 30px;
}

.rvps6 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 20px 30px 30px 30px;
}

.rvps7 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 30px 10px 30px;
}

.rvps8 {
  text-align: justify;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 0 10px 0;
}

.rvps9 {
  text-align: left;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 0 10px 6px;
}

.rvps10 {
  text-align: right;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 0 10px 0;
}

.rvps11 {
  text-align: right;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 0 10px 0;
}

.rvps12 {
  text-align: center;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 0 10px 0;
}

.rvps13 {
  text-align: justify;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.rvps14 {
  text-align: left;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 0 10px 0;
}

.rvps15 {
  text-align: right;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 20px 0 0 0;
}

.rvps16 {
  text-align: left;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 20px 0 0 0;
}

.rvps17 {
  text-align: center;
  text-indent: 0;
  line-height: 1.5;
  padding: 0 0 0 0;
  margin: 20px 30px 0 30px;
}

.rvps18 {
  text-align: left;
  text-indent: 0;
  padding: 0 0 0 0;
  margin: 10px 30px 20px 30px;
}
