@import "../theme.scss";

.loaderScreen {
  text-align: center;
  background-color: $primary-color;
  color: $dark-text-color-1;
}

.dark {
  color: $dark-text-color-1;
}
