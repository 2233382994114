$primary-color: #336495;

$text-color-1: #3972ac;
$text-color-2: rgba(6, 12, 19, 0.65);
$text-color-3: rgba(6, 12, 19, 0.25);
$text-color-4: #fff;
$text-color-5: #060c13;
$text-color-6: #e71b10;

$background-color-1: #e5e5e6;
$background-color-2: rgba(6, 12, 19, 0.06);
$background-color-3: #ffebe6;

$border-color-1: $primary-color;
$border-color-2: #f0f0f0;

$transition-1: all 0.2s ease-out;

$dark-text-color-1: #ffffff;
$dark-text-color-2: rgba(255, 255, 255, 0.65);
$dark-text-color-3: rgba(255, 255, 255, 0.25);
$dark-text-color-4: #14191f;
$dark-text-color-5: #c71a11;

$dark-background-color-1: #060c13;
$dark-background-color-2: #14191f;
$dark-background-color-3: #281111;

$dark-border-color-1: #1d3b5d;
