@import "../theme.scss";

.box {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  gap: 16px;
  user-select: none;
  cursor: pointer;
  &.dark {
    .favorite,
    .right {
      color: $dark-text-color-3;
    }
  }
}

.favorite,
.right {
  flex: 0;
  color: $text-color-3;
  &.dark {
    color: $dark-text-color-3;
  }
}

.favoriteActive {
  color: $text-color-1;
}

.textBox {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 0px !important;
}

.subtitle {
  margin-top: 8px;
}

.gap {
  flex: 1;
}

.button {
  cursor: pointer;
}
